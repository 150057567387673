import { backendAuthEndpoints } from 'apis/backend/services/Auth';
import axios from 'axios';
import { checkAuthStatus, refreshServiceToken } from 'contexts/JWTContext';

// custom
import objToCamelCase, { objToSnakeCase } from 'apis/backend/Utils';

const axiosServices = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3010/'
});

// ==============================|| AXIOS - FOR MOCK SERVICES ||============================== //

axiosServices.interceptors.response.use(
  (response) => {
    //console.log(`Response data....${response.data}`)
    if (response.data) {
      response.data = objToCamelCase(response.data);
    }
    return response;
  },
  (error) => {
    // let host = window.location.host;
    // let parts = host.split(".");
    // let subdomain = parts[0];
    // if (error.response.status === 401 && subdomain == 'app' && !window.location.href.includes('/login')) {
    //   window.location = '/login';
    // }
    if (error) {
      error = objToCamelCase(error);
    }
    return Promise.reject((error.response && error.response.data) || 'Wrong Services');
  }
);

axiosServices.interceptors.request.use(
  async (config) => {
    // Update naming convention from CamelCase to the underscored type
    if (config.data) {
      // console.log(config.data)
      config.data = objToSnakeCase(config.data); // DON'T FORGET TO HANDLE FILES!
      // console.log(config.data)
    }

    // Check authentication status
    const authStatus = checkAuthStatus();
    const targetUrl = config.url;
    const refreshUrl = backendAuthEndpoints.refreshToken;
    let serviceToken = localStorage.getItem('serviceToken');

    if (!authStatus && serviceToken) {
      console.log(`Auth status(false). Target url - ${targetUrl}. Refresh url - ${refreshUrl}`);
      if (targetUrl !== refreshUrl) {
        console.log('Would try to refresh');
        await refreshServiceToken();

        // Update this call's auth config
        serviceToken = localStorage.getItem('serviceToken'); // Get the newly created token
        config.headers.Authorization = `Bearer ${serviceToken}`;
      }
    }
    return config;
  },
  (error) => error
);

export default axiosServices;

export const { get, post, put, delete: destroy } = axiosServices;
