// third-party
import {
  //createAsyncThunk,
  createSlice
} from '@reduxjs/toolkit';

// project imports
import { v4 as uuidv4 } from 'uuid';

// ----------------------------------------------------------------------
/*
  Products
  - products: all products
  - product: the currently viewed product (for editing)

  pricing = {
    price: number,
    currency: string, // USD, EUR, etc. // default USD
    recurring: boolean, // true if the product is recurring // default false
    recurringType: string, // monthly, yearly, etc. // default none
  }

  product = {
    uuid: string,
    publicId: string,
    name: string,
    description: string,
    pricing: pricing,
    dateCreated: string,
    dateUpdated: string,
  }
*/

// Tracks the products of the company
// const initialState = {
//   error: null,
//   products: [],
//   product: null, // The currently viewed product
// };

// export function productReducer(state, action) {
//   switch (action.type) {
//     case productActions.ADD_PRODUCT:
//       return {
//         ...state,
//         products: [...state.products, { ...action.payload, uuid: uuidv4() }]
//       }
//     case productActions.UPDATE_PRODUCT:
//       return {
//         ...state,
//         products: state.products.map(p => p.uuid === action.payload.uuid ? action.payload : p)
//       }
//     case productActions.DELETE_PRODUCT:
//       return {
//         ...state,
//         products: state.products.filter(p => p.uuid !== action.payload)
//       }
//     case productActions.SET_EDITING:
//       return { ...state, editingId: action.payload }
//     default:
//       return state
//   }
// }

// export function addProduct(product) {
//   return { type: productActions.ADD_PRODUCT, payload: product }
// }

// export function updateProduct(product) {
//   return { type: productActions.UPDATE_PRODUCT, payload: product }
// }

// export function deleteProduct(uuid) {
//   return { type: productActions.DELETE_PRODUCT, payload: uuid }
// }

// export function setEditing(uuid) {
//   return { type: productActions.SET_EDITING, payload: uuid }
// }

// const product = createSlice({
//   name: 'product',
//   initialState,
//   reducers: {
//     // HAS ERROR
//     hasError(state, action) {
//       state.error = action.payload;
//     },
//     // CLEAR ERROR
//     clearError(state) {
//       state.error = null;
//     },

//     // GET CATEGORIES
//     getProductsSuccess(state, action) {
//       state.products = action.payload;
//     },

//     // FILTER CATEGORIES
//     filterProductsSuccess(state, action) {
//       state.products = action.payload;
//     },

//     // CREATE NEW CATEGORY
//     createProductSuccess(state, action) {
//       state.products.push(action.payload);
//     },

//     // UPDATE CATEGORY
//     updateProductSuccess(state, action) {
//       const changedProduct = action.payload;
//       state.products = state.products.map((obj) => {
//         return obj.uuid === changedProduct.uuid ? changedProduct : obj;
//       });
//     },

//     // DELETE CATEGORY
//     deleteProductSuccess(state, action) {
//       state.products = state.products.filter(function (product) {
//         if (product.uuid !== action.payload) {
//           return product;
//         }
//       });
//     },

//     // GET CATEGORY
//     getProductSuccess(state, action) {
//       state.product = action.payload;
//     },

//     addProductPatentsSuccess(state, action) {
//       const updatedProduct = action.payload;
//       // Update the product in the overall product list
//       state.products = state.products.map((obj) => {
//         return obj.uuid === updatedProduct.uuid ? updatedProduct : obj;
//       });

//       // Update the currently viewed product
//       state.product = action.payload;
//     },

//     removeProductPatentsSuccess(state, action) {
//       const updatedProduct = action.payload;
//       // Update the product in the overall product list
//       state.products = state.products.map((obj) => {
//         return obj.uuid === updatedProduct.uuid ? updatedProduct : obj;
//       });

//       // Update the currently viewed product
//       state.product = action.payload;
//     }
//   }
// });
const initialState = {
  error: null,
  products: [
    {
      uuid: 'b3c25b8b-8df9-4f9c-9f0d-5e9c32c2a36f',
      name: 'Basic AI Assistant',
      price: 29.99,
      recurringType: 'monthly',
      description: 'AI-powered virtual assistant with basic functionality'
    },
    {
      uuid: '7d9a2c54-e68c-4b9b-ae4f-f555c3a7b123',
      name: 'Pro AI Assistant',
      price: 99.99,
      recurringType: 'monthly',
      description: 'Advanced AI assistant with premium features'
    },
    {
      uuid: '1f4e8d36-b52a-4c97-b8f3-90d6e512def4',
      name: 'Enterprise License',
      price: 999.99,
      recurringType: 'yearly',
      description: 'Full enterprise deployment with dedicated support'
    }
  ],
  expenseTypes: [
    { id: '1', name: 'Server Costs' },
    { id: '2', name: 'API Services' }
    // { id: '3', name: 'Raw Materials' },
  ],
  productExpenses: [],
  product: null, // The currently viewed product
  editingId: null
};

const product = createSlice({
  name: 'product',
  initialState,
  reducers: {
    addProduct: (state, action) => {
      state.products.push({ ...action.payload, uuid: uuidv4() });
    },
    updateProduct: (state, action) => {
      const index = state.products.findIndex((p) => p.uuid === action.payload.uuid);
      if (index !== -1) {
        state.products[index] = action.payload;
      }
    },
    deleteProduct: (state, action) => {
      state.products = state.products.filter((p) => p.uuid !== action.payload);
      state.productExpenses = state.productExpenses.filter((pe) => pe.productId !== action.payload);
      state.product = state.product === action.payload ? null : state.product;
    },
    setEditing: (state, action) => {
      state.editingId = action.payload;
    },
    addExpenseType: (state, action) => {
      state.expenseTypes.push({ id: uuidv4(), name: action.payload });
    },
    updateProductExpense: (state, action) => {
      const { productId, expenseTypeId, cost } = action.payload;
      const existingExpenseIndex = state.productExpenses.findIndex(
        (pe) => pe.productId === productId && pe.expenseTypeId === expenseTypeId
      );
      if (existingExpenseIndex !== -1) {
        state.productExpenses[existingExpenseIndex] = { productId, expenseTypeId, cost };
      } else {
        state.productExpenses.push({ productId, expenseTypeId, cost });
      }
    },
    deleteProductExpense: (state, action) => {
      const { productId, expenseTypeId } = action.payload;
      state.productExpenses = state.productExpenses.filter((pe) => !(pe.productId === productId && pe.expenseTypeId === expenseTypeId));
    }
  }
});

export const { addProduct, updateProduct, deleteProduct, setEditing, addExpenseType, updateProductExpense, deleteProductExpense } =
  product.actions;

export default product.reducer;

// ----------------------------------------------------------------------
// export function clearError() {
//   return dispatch(product.actions.clearError());
// }

// export function getProducts(params) {
//   return async () => {
//     try {
//       const response = await Products.index(params);
//       dispatch(product.actions.getProductsSuccess(response.data));
//     } catch (error) {
//       dispatch(product.actions.hasError(error));
//     }
//   };
// }

// export function filterProducts(filter) {
//   return async () => {
//     try {
//       const response = await axios.post('/api/products/filter', { filter });
//       dispatch(product.actions.filterProductsSuccess(response.data));
//     } catch (error) {
//       dispatch(product.actions.hasError(error));
//     }
//   };
// }

// export function createProduct(formValues) {
//   return async () => {
//     try {
//       const response = await Products.create(formValues); // API to create element
//       console.log(response);
//       dispatch(product.actions.createProductSuccess(response.data)); // Dispatch resulting Product obj
//     } catch (error) {
//       console.log(error);
//       dispatch(product.actions.hasError(error));
//     }
//   };
// }

// export function updateProduct(uuid, formValues) {
//   return async (dispatch, getState) => {
//     try {
//       const response = await Products.update(uuid, formValues); // API to update element
//       console.log(response);
//       dispatch(product.actions.updateProductSuccess(response.data)); // Dispatch resulting Product obj
//     } catch (error) {
//       console.log(error);
//       dispatch(product.actions.hasError(error));
//       console.log(getState());
//     }
//   };
// }

// export function deleteProduct(uuid) {
//   return async () => {
//     try {
//       const response = await Products.remove(uuid); // API to delete element
//       console.log(response);
//       dispatch(product.actions.deleteProductSuccess(uuid)); // Dispatch cat to delete
//     } catch (error) {
//       dispatch(product.actions.hasError(error));
//     }
//   };
// }

// export function getProduct(uuid) {
//   return async () => {
//     try {
//       const response = await Products.single(uuid);
//       dispatch(product.actions.getProductSuccess(response.data));
//     } catch (error) {
//       dispatch(product.actions.hasError(error));
//     }
//   };
// }

// export function addProductPatents(uuid, params) {
//   return async () => {
//     try {
//       const response = await Products.addPatents(uuid, params);
//       dispatch(product.actions.addProductPatentsSuccess(response.data));
//       console.log(response.data);
//     } catch (error) {
//       dispatch(product.actions.hasError(error));
//     }
//   };
// }

// export function removeProductPatents(uuid, params) {
//   return async () => {
//     try {
//       const response = await Products.removePatents(uuid, params);
//       dispatch(product.actions.removeProductPatentsSuccess(response.data));
//     } catch (error) {
//       dispatch(product.actions.hasError(error));
//     }
//   };
// }
