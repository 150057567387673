// material-ui

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoIconDark from 'assets/images/logo-icon-dark.svg';
 * import logoIcon from 'assets/images/logo-icon.svg';
 * import { ThemeMode } from 'config';
 *
 */

// ==============================|| LOGO ICON SVG ||============================== //

const LogoIcon = () => {
  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === ThemeMode.DARK ? logoIconDark : logoIcon} alt="Mantis" width="100" />
     *
     */
    <svg width="266" height="420" viewBox="0 0 19 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.6875 0.535715H3.63393V3.48214H0.6875V0.535715ZM3.63393 0.535715H6.58036V3.48214H3.63393V0.535715ZM6.58036 0.535715H9.52679V3.48214H6.58036V0.535715ZM9.52679 0.535715H12.4732V3.48214H9.52679V0.535715ZM12.4732 0.535715H15.4196V3.48214H12.4732V0.535715ZM15.4196 0.535715H18.3661V3.48214H15.4196V0.535715ZM15.4196 3.48214H18.3661V6.42857H15.4196V3.48214ZM12.4732 3.48214H15.4196V6.42857H12.4732V3.48214ZM9.52679 3.48214H12.4732V6.42857H9.52679V3.48214ZM6.58036 3.48214H9.52679V6.42857H6.58036V3.48214ZM3.63393 3.48214H6.58036V6.42857H3.63393V3.48214ZM0.6875 3.48214H3.63393V6.42857H0.6875V3.48214ZM6.58036 6.42857H9.52679V9.375H6.58036V6.42857ZM6.58036 9.375H9.52679V12.3214H6.58036V9.375ZM6.58036 12.3214H9.52679V15.2679H6.58036V12.3214ZM6.58036 15.2679H9.52679V18.2143H6.58036V15.2679ZM6.58036 18.2143H9.52679V21.1607H6.58036V18.2143ZM6.58036 21.1607H9.52679V24.1071H6.58036V21.1607ZM9.52679 18.2143H12.4732V21.1607H9.52679V18.2143ZM9.52679 15.2679H12.4732V18.2143H9.52679V15.2679ZM9.52679 12.3214H12.4732V15.2679H9.52679V12.3214ZM9.52679 9.375H12.4732V12.3214H9.52679V9.375ZM9.52679 6.42857H12.4732V9.375H9.52679V6.42857ZM9.52679 21.1607H12.4732V24.1071H9.52679V21.1607ZM9.52679 24.1071H12.4732V27.0536H9.52679V24.1071ZM9.52679 27.0536H12.4732V30H9.52679V27.0536ZM6.58036 27.0536H9.52679V30H6.58036V27.0536ZM6.58036 24.1071H9.52679V27.0536H6.58036V24.1071Z"
        fill="url(#paint0_angular_54_2)"
      />
      <defs>
        <radialGradient
          id="paint0_angular_54_2"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(11 15) rotate(61.1892) scale(22.8254)"
        >
          <stop />
          <stop offset="1" stopColor="#666666" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default LogoIcon;
