import { useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { Button, Box } from '@mui/material';
import { ThemeMode } from 'config';
import { ExportOutlined } from '@ant-design/icons';

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

const PublicViewLink = ({ company }) => {
  const theme = useTheme();
  const anchorRef = useRef(null);
  const handleToggle = () => {
    const host = process.env.REACT_APP_BACKEND_HOST;
    const url = `http://${company.domain.name}.${host}`;
    window.open(url, '_blank');
  };

  const iconBackColor = theme.palette.mode === ThemeMode.DARK ? 'background.default' : 'grey.100';

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <Button
        color="secondary"
        variant="light"
        sx={{ color: 'text.primary', bgcolor: iconBackColor }}
        aria-label="open profile"
        ref={anchorRef}
        aria-haspopup="true"
        onClick={handleToggle}
        endIcon={<ExportOutlined />}
      >
        Public View
      </Button>
    </Box>
  );
};

export default PublicViewLink;
