// Custom import
// import manage from './manage';
// import accountConfig from './account-config';
// import adminMgmt from './admin-mgmt';
import goToMarket from './go-to-market';
// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [
    goToMarket
    // manage,
    // accountConfig,
    // adminMgmt //
  ]
};

export default menuItems;
