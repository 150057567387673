// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  ExperimentOutlined,
  FileDoneOutlined,
  HomeOutlined,
  BuildOutlined,
  CalendarOutlined,
  CustomerServiceOutlined,
  FileSearchOutlined,
  SolutionOutlined,
  RobotOutlined,
  DatabaseOutlined,
  TeamOutlined,
  InfoCircleOutlined,
  ExpandOutlined,
  LineChartOutlined,
  PartitionOutlined,
  BarChartOutlined,
  UnorderedListOutlined // New icon for list representation
} from '@ant-design/icons';

// icons
const icons = {
  ExperimentOutlined,
  FileDoneOutlined,
  HomeOutlined,
  BuildOutlined,
  CalendarOutlined,
  CustomerServiceOutlined,
  FileSearchOutlined,
  SolutionOutlined,
  RobotOutlined,
  DatabaseOutlined,
  TeamOutlined,
  InfoCircleOutlined,
  ExpandOutlined, // Icon for sizing
  LineChartOutlined, // Icon for analytics
  PartitionOutlined, // Icon for segment management
  BarChartOutlined, // Icon for fit analysis
  UnorderedListOutlined // New icon for list
};
// ==============================|| MENU ITEMS - ACCOUNT CONFIG ||============================== //

const goToMarket = {
  id: 'group-go-to-market',
  title: 'Go To Market',
  icon: icons.DatabaseOutlined,
  type: 'group',
  children: [
    {
      id: 'strategy',
      title: 'Dashboard',
      type: 'item',
      url: '/app/',
      icon: icons.InfoCircleOutlined
    },
    {
      id: 'sales',
      title: 'Channel Analytics',
      type: 'item',
      url: '/app/sales',
      icon: icons.LineChartOutlined // Icon for analytics
    },
    {
      id: 'experiments',
      title: 'Experiments',
      type: 'item',
      url: '/app/experiments',
      icon: icons.ExperimentOutlined
    },
    {
      id: 'segments',
      title: 'Segments Manager',
      type: 'collapse',
      url: '/app/market-segments',
      icon: icons.PartitionOutlined, // Icon for segment management
      children: [
        {
          id: 'market-segments',
          title: 'List',
          type: 'item',
          url: '/app/market-segments',
          icon: icons.UnorderedListOutlined // Updated icon for list
        },
        {
          id: 'market-segments-analytics',
          title: 'Analytics',
          type: 'item',
          url: '/app/market-segments/analytics',
          icon: icons.SolutionOutlined // Updated icon for creation
        },
        {
          id: 'market-segments-creator',
          title: 'Create New',
          type: 'item',
          url: '/app/market-segments/search',
          icon: icons.SolutionOutlined // Updated icon for creation
        },
        {
          id: 'segment-details',
          title: 'Details',
          type: 'item',
          url: '/app/market-segments/details',
          icon: icons.InfoCircleOutlined // Icon for details
        },
        {
          id: 'segment-fit',
          title: 'Fit Analysis',
          type: 'item',
          url: '/app/market-segments/fit',
          icon: icons.BarChartOutlined // Icon for fit analysis
        }
      ]
    },
    {
      id: 'sizing',
      title: 'TAM / SAM / SOM',
      type: 'item',
      url: '/app/sizing',
      icon: icons.ExpandOutlined // Icon for sizing
    },
    {
      id: 'ai',
      title: 'AI Widget Creator',
      type: 'item',
      url: '/app/ai',
      icon: icons.RobotOutlined
    }
  ]
};

export default goToMarket;
