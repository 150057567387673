// project-import
import { ThemeMode } from 'config';

// ==============================|| PRESET THEME - AI Sales Optimizer ||============================== //

const Theme1 = (colors, mode) => {
  const { grey } = colors;
  const greyColors = {
    0: grey[0],
    50: grey[1],
    100: grey[2],
    200: grey[3],
    300: grey[4],
    400: grey[5],
    500: grey[6],
    600: grey[7],
    700: grey[8],
    800: grey[9],
    900: grey[10],
    A50: grey[15],
    A100: grey[11],
    A200: grey[12],
    A400: grey[13],
    A700: grey[14],
    A800: grey[16]
  };
  const contrastText = '#fff';

  // Base theme colors from the new scheme
  let primaryColors = ['#E6F0FF', '#B3D1FF', '#80B3FF', '#4D94FF', '#1A75FF', '#0052CC', '#003D99', '#002966', '#001433', '#000A1A'];
  let secondaryColors = ['#E6FCFF', '#B3F5FF', '#80EEFF', '#4DE7FF', '#1AE0FF', '#00B8D9', '#008DA6', '#006273', '#003140', '#00191F'];

  let errorColors = ['#FFE6E6', '#FFB3B3', '#FF8080', '#FF4D4D', '#FF1A1A', '#CC0000', '#990000'];
  let warningColors = ['#FFF6E6', '#FFE2B3', '#FFCE80', '#FFBA4D', '#FFA61A', '#CC7A00', '#995C00'];
  let infoColors = ['#E6F4FF', '#B3E0FF', '#80CCFF', '#4DB8FF', '#1AA3FF', '#0080CC', '#006099'];
  let successColors = ['#E6FFE6', '#B3FFB3', '#80FF80', '#4DFF4D', '#1AFF1A', '#00CC00', '#009900'];

  if (mode === ThemeMode.DARK) {
    primaryColors = ['#000A1A', '#001433', '#002966', '#003D99', '#0052CC', '#1A75FF', '#4D94FF', '#80B3FF', '#B3D1FF', '#E6F0FF'];
    secondaryColors = ['#00191F', '#003140', '#006273', '#008DA6', '#00B8D9', '#1AE0FF', '#4DE7FF', '#80EEFF', '#B3F5FF', '#E6FCFF'];
    errorColors = ['#990000', '#CC0000', '#FF1A1A', '#FF4D4D', '#FF8080', '#FFB3B3', '#FFE6E6'];
    warningColors = ['#995C00', '#CC7A00', '#FFA61A', '#FFBA4D', '#FFCE80', '#FFE2B3', '#FFF6E6'];
    infoColors = ['#006099', '#0080CC', '#1AA3FF', '#4DB8FF', '#80CCFF', '#B3E0FF', '#E6F4FF'];
    successColors = ['#009900', '#00CC00', '#1AFF1A', '#4DFF4D', '#80FF80', '#B3FFB3', '#E6FFE6'];
  }

  return {
    primary: {
      lighter: primaryColors[0],
      100: primaryColors[1],
      200: primaryColors[2],
      light: primaryColors[3],
      400: primaryColors[4],
      main: primaryColors[5], // #0052CC (Deep Blue)
      dark: primaryColors[6],
      700: primaryColors[7],
      darker: primaryColors[8],
      900: primaryColors[9],
      contrastText
    },
    secondary: {
      lighter: secondaryColors[0],
      100: secondaryColors[1],
      200: secondaryColors[2],
      light: secondaryColors[3],
      400: secondaryColors[4],
      main: secondaryColors[5], // #00B8D9 (Teal)
      dark: secondaryColors[6],
      700: secondaryColors[7],
      darker: secondaryColors[8],
      900: secondaryColors[9],
      contrastText
    },
    error: {
      lighter: errorColors[0],
      light: errorColors[1],
      main: errorColors[4], // #FF1A1A
      dark: errorColors[5],
      darker: errorColors[6],
      contrastText
    },
    warning: {
      lighter: warningColors[0],
      light: warningColors[1],
      main: warningColors[4], // #FFA61A
      dark: warningColors[5],
      darker: warningColors[6],
      contrastText: greyColors[100]
    },
    info: {
      lighter: infoColors[0],
      light: infoColors[1],
      main: infoColors[4], // #1AA3FF
      dark: infoColors[5],
      darker: infoColors[6],
      contrastText
    },
    success: {
      lighter: successColors[0],
      light: successColors[1],
      main: successColors[4], // #1AFF1A
      dark: successColors[5],
      darker: successColors[6],
      contrastText
    },
    grey: greyColors
  };
};

export default Theme1;