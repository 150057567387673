import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party
//import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';

// project import
import Loader from 'components/Loader';
import axios from 'utils/axios';
import { backendAuthEndpoints } from 'apis/backend/services/Auth';
import { Users } from 'apis/backend/services/User';

//const chance = new Chance();

// constant
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

const verifyToken = (serviceToken) => {
  if (!serviceToken) {
    return false;
  }
  const decoded = jwtDecode(serviceToken);
  /**
   * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
   */
  console.log(`Decoded expiration time - ${decoded.exp}`);
  let tokenIsValid = decoded.exp > Date.now() / 1000;
  console.log(`Token is valid ${tokenIsValid}`);
  return tokenIsValid;
};

const setSession = (serviceToken) => {
  if (serviceToken) {
    localStorage.setItem('serviceToken', serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem('serviceToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const setRefresh = (refreshToken) => {
  if (refreshToken) {
    localStorage.setItem('refreshToken', refreshToken);
  } else {
    localStorage.removeItem('refreshToken');
  }
};

export const refreshServiceToken = async () => {
  console.log('refreshing service access token...');
  const refreshToken = localStorage.getItem('refreshToken');
  if (refreshToken) {
    return axios
      .post(
        backendAuthEndpoints.refreshToken,
        {
          refresh: refreshToken
        },
        {
          headers: { 'Content-Type': 'application/json' }
        }
      )
      .then((response) => {
        const access = response.data.access; //get token from response
        console.log(`New service access token ${access}`);
        setSession(access); //set JWT token to local
        // window.location.reload(false);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  } else {
    console.log(`No token to refresh...`);
    return null;
  }
};
export const checkAuthStatus = () => {
  // function to determine whether user has a service token and
  // whether or not it needs to be refreshed.
  let serviceToken = window.localStorage.getItem('serviceToken');
  let tokenValid = verifyToken(serviceToken);

  return tokenValid;
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const init = async () => {
      try {
        let serviceToken = window.localStorage.getItem('serviceToken');
        let tokenValid = verifyToken(serviceToken);

        if (serviceToken && !tokenValid) {
          // Attempt to refresh the service token
          const response = await refreshServiceToken();
          if (response.data.access) {
            // successfully updated service access token
            serviceToken = response.data.access; // update to new value
            tokenValid = verifyToken(serviceToken); // double confirm now valid
            if (!tokenValid) {
              console.log('Issue refreshing the access/service token');
            } else {
              console.log('Successfully refreshed token');
              setSession(serviceToken);
            }
          }
        }

        if (serviceToken && tokenValid && !state.user) {
          setSession(serviceToken);
          const response = await Users.me();
          const user = response.data;
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user
            }
          });
        } else {
          dispatch({
            type: LOGOUT
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: LOGOUT
        });
      }
    };

    init();
  }, []);

  const login = async (email, password) => {
    const response = await axios.post(backendAuthEndpoints.obtainTokenPair, {
      email,
      password
    });
    const { access, refresh, user } = response.data; // Get back access and refresh tokens
    setSession(access); // Setup the session calls to prep for user retrieve
    setRefresh(refresh); // Configure the refresh token and time till expired

    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user
      }
    });
  };

  const register = async (email, password, firstName, lastName) => {
    // todo: this flow need to be recode as it not verified
    //const id = chance.bb_pin();
    logout(); // First confirm we're not logged in

    const response = await Users.create({
      email,
      password,
      firstName,
      lastName
    });
    let users = response.data;
    const { id } = users;

    if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
      const localUsers = window.localStorage.getItem('users');
      const parsedUsers = JSON.parse(localUsers);
      console.log(parsedUsers);
      if (parsedUsers) {
        users = [
          Object.entries(parsedUsers),
          {
            id,
            email,
            password,
            name: `${firstName} ${lastName}`
          }
        ];
      }
    }
    window.localStorage.setItem('users', JSON.stringify(users));
  };

  const logout = () => {
    setSession(null);
    setRefresh(null);
    localStorage.removeItem('users');
    console.log('Session cleared...');
    dispatch({ type: LOGOUT });
  };

  const resetPassword = async () => {};

  const updatePassword = async (user, params) => {
    const response = await Users.updatePassword(user.id, params);
    console.log(response);
    return response;
  };

  const updateProfile = async (user, params) => {
    const response = await Users.update(user.id, params);
    console.log(response);
    const updatedUser = response.data;

    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user: updatedUser
      }
    });
    return response;
  };

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider
      value={{
        ...state,
        login,
        logout,
        register,
        resetPassword,
        updatePassword,
        updateProfile
      }}
    >
      {children}
    </JWTContext.Provider>
  );
};

JWTProvider.propTypes = {
  children: PropTypes.node
};

export default JWTContext;
