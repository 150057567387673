// material-ui
import { Box, useMediaQuery } from '@mui/material';

// Redux Store
import { useSelector } from 'store';

import Profile from './Profile';
import Notification from './Notification';
import MobileSection from './MobileSection';

import { MenuOrientation } from 'config';
import useConfig from 'hooks/useConfig';
import DrawerHeader from 'components/layout/MainLayout/Drawer/DrawerHeader';
import PublicViewLink from './PublicViewLink';
import SearchInput from 'components/common/controls/SearchInput';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const { menuOrientation } = useConfig();
  const { myCompany } = useSelector((state) => state.company);

  const downLG = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  return (
    <>
      {menuOrientation === MenuOrientation.HORIZONTAL && !downLG && <DrawerHeader open={true} />}
      {!downLG && <SearchInput placeholder="Ctrl + K" />}
      {downLG && <Box sx={{ width: '100%', ml: 1 }} />}
      {myCompany && <PublicViewLink company={myCompany} />}
      <Notification />
      {!downLG && <Profile />}
      {downLG && <MobileSection />}
    </>
  );
};

export default HeaderContent;
