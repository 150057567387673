// third-party
import {
  //createAsyncThunk,
  createSlice
} from '@reduxjs/toolkit';

// type SalesData = {
//   productId: string;
//   month: string;
//   sales: number;
// };

// type InflectionPoint = {
//   id: string;
//   startDate: string;
//   growthRate: number;
// };

// type SalesState = {
//   salesData: SalesData[];
//   inflectionPoints: InflectionPoint[];
// };

const initialState = {
  salesData: [
    {
      productId: 'b3c25b8b-8df9-4f9c-9f0d-5e9c32c2a36f',
      month: '2024-10',
      sales: 100
    }
  ],
  inflectionPoints: [
    {
      id: '1',
      startDate: '2024-01',
      growthRate: 0.1
    }
  ]
};

const salesSlice = createSlice({
  name: 'sales',
  initialState,
  reducers: {
    addSalesData: (state, action) => {
      state.salesData.push(action.payload);
    },
    updateSalesData: (state, action) => {
      const index = state.salesData.findIndex((sd) => sd.productId === action.payload.productId && sd.month === action.payload.month);
      if (index !== -1) {
        state.salesData[index] = action.payload;
      } else {
        state.salesData.push(action.payload);
      }
    },
    addInflectionPoint: (state, action) => {
      state.inflectionPoints.push(action.payload);
    },
    updateInflectionPoint: (state, action) => {
      const index = state.inflectionPoints.findIndex((ip) => ip.id === action.payload.id);
      if (index !== -1) {
        state.inflectionPoints[index] = action.payload;
      }
    },
    deleteInflectionPoint: (state, action) => {
      state.inflectionPoints = state.inflectionPoints.filter((ip) => ip.id !== action.payload);
    },
    deleteProductSalesData: (state, action) => {
      state.salesData = state.salesData.filter((sd) => sd.productId !== action.payload);
    }
  }
});

export const { addSalesData, updateSalesData, addInflectionPoint, updateInflectionPoint, deleteInflectionPoint, deleteProductSalesData } =
  salesSlice.actions;

export default salesSlice.reducer;
