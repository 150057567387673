// third-party
import {
  //createAsyncThunk,
  createSlice
} from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';
import { Companies, Domains, Industries } from 'apis/backend/services/companies/Company';

// ----------------------------------------------------------------------

const initialState = {
  isVerified: false,
  company: null,
  companyError: null,
  domain: null,
  domains: [],
  domainsError: null,
  industries: [],
  industriesError: null
};

const company = createSlice({
  name: 'company',
  initialState,
  reducers: {
    // HAS ERROR
    hasCompanyError(state, action) {
      state.companyError = action.payload;
    },
    // CLEAR ERROR
    clearCompanyError(state) {
      state.companyError = null;
    },

    // HAS ERROR
    hasDomainsError(state, action) {
      state.domainsError = action.payload;
    },
    // CLEAR ERROR
    clearDomainsError(state) {
      state.domainsError = null;
    },

    // HAS ERROR
    hasIndustriesError(state, action) {
      state.industriesError = action.payload;
    },
    // CLEAR ERROR
    clearIndustriesError(state) {
      state.industriesError = null;
    },

    // CLEAR ERROR
    clearAllErrors(state) {
      state.companyError = null;
      state.domainsError = null;
      state.industriesError = null;
    },

    // GET COMPANY
    getCompanySuccess(state, action) {
      state.company = action.payload;
    },

    // CREATE NEW COMPANY
    createCompanySuccess(state, action) {
      state.company = action.payload;
    },

    // UPDATE COMPANY
    updateCompanySuccess(state, action) {
      state.company = action.payload;
    },

    // DELETE COMPANY
    deleteCompanySuccess(state) {
      state.company = null;
    },

    // GET DOMAIN
    getDomainExistsSuccess(state, action) {
      const domain = action.payload;
      var index = state.domains.findIndex((x) => x.uuid === domain.name);
      index === -1
        ? state.domains.push(action.payload)
        : state.domains.map((obj) => {
            return obj.name === domain.name ? domain : obj;
          });
    },

    // Get single domain success. this is used for public
    // facing urls which need access to company data by domain-name
    getDomainSuccess(state, action) {
      state.domain = action.payload;
      state.company = action.payload.company;
    },

    // GET INDUSTRIES
    getIndustriesSuccess(state, action) {
      state.industries = action.payload;
    }
  }
});

// Reducer
export default company.reducer;

// ----------------------------------------------------------------------
export function clearCompanyError() {
  return dispatch(company.actions.clearCompanyError());
}

export function clearDomainsError() {
  return dispatch(company.actions.clearDomainsError());
}
export function clearIndustriesError() {
  return dispatch(company.actions.clearIndustriesError());
}

export function getCompany() {
  return async () => {
    try {
      const response = await Companies.mine();
      dispatch(company.actions.getCompanySuccess(response.data));
    } catch (error) {
      dispatch(company.actions.hasCompanyError(error));
    }
  };
}

export function createCompany(formValues) {
  return async () => {
    try {
      const response = await Companies.create(formValues); // API to create element
      console.log(response);
      dispatch(company.actions.createCompanySuccess(response.data)); // Dispatch resulting Company obj
    } catch (error) {
      console.log(error);
      dispatch(company.actions.hasCompanyError(error));
    }
  };
}

export function updateCompany(uuid, formValues) {
  return async (dispatch, getState) => {
    try {
      const response = await Companies.update(uuid, formValues); // API to update element
      console.log(response);
      dispatch(company.actions.updateCompanySuccess(response.data)); // Dispatch resulting Company obj
    } catch (error) {
      console.log(error);
      dispatch(company.actions.hasCompanyError(error));
      console.log(getState());
    }
  };
}

export function deleteCompany(uuid) {
  return async () => {
    try {
      const response = await Companies.remove(uuid); // API to delete element
      console.log(response);
      dispatch(company.actions.deleteCompanySuccess(uuid)); // Dispatch cat to delete
    } catch (error) {
      dispatch(company.actions.hasCompanyError(error));
    }
  };
}

export function getDomainExists(name) {
  return async (dispatch, getState) => {
    var index = getState().company.domains.findIndex((x) => x.name === name);
    // console.log(`Name - ${name}, index = ${index}`)

    try {
      if (index === -1) {
        // Haven't retreived this name yet
        const response = await Domains.exists(name); // { exists: t/f }
        const domain = {
          name: name,
          ...response.data
        };
        dispatch(company.actions.getDomainExistsSuccess(domain));
      } else {
        dispatch(company.actions.getDomainExistsSuccess(getState().company.domains[index]));
      }
    } catch (error) {
      console.log(error);
      dispatch(company.actions.hasDomainsError(error.message));
    }
  };
}

export function getDomain(name) {
  return async (dispatch) => {
    try {
      const response = await Domains.single(name);
      dispatch(company.actions.getDomainSuccess(response.data));
    } catch (error) {
      console.log(error);
      dispatch(company.actions.hasDomainsError(error.message));
    }
  };
}

export function getIndustries() {
  return async (dispatch, getState) => {
    try {
      const industries = getState().company.industries;
      if (industries && !industries.length) {
        const response = await Industries.index();
        dispatch(company.actions.getIndustriesSuccess(response.data));
      }
    } catch (error) {
      console.log(error);
      dispatch(company.actions.hasIndustriesError(error));
    }
  };
}
