import { createSlice } from '@reduxjs/toolkit';

import { dispatch } from 'store';
import { generateMonthlyMetrics } from '../../components/financial/models/forecastCalculator';
import { calculateFinancials, subsChanges } from '../../components/financial/models/forecastCalculator';

const growthRate = 0.15;
const initialMetrics = {
  endDate: '01-01-2024',
  metrics: [
    {
      key: 'unitsSold',
      name: 'Units Sold',
      subCategories: [
        { key: 'hobbyTier', name: 'Hobby Tier', value: 1456, type: 'number', unit: 'ea' },
        { key: 'basicTier', name: 'Basic Tier', value: 678, type: 'number', unit: 'ea' },
        { key: 'proTier', name: 'Pro Tier', value: 98, type: 'number', unit: 'ea' }
      ]
    },
    {
      key: 'pricing',
      name: 'Pricing',
      subCategories: [
        { key: 'hobbyTier', name: 'Hobby Tier', value: 19.99, type: 'number', unit: 'USD' },
        { key: 'basicTier', name: 'Basic Tier', value: 49.99, type: 'number', unit: 'USD' },
        { key: 'proTier', name: 'Pro Tier', value: 99.99, type: 'number', unit: 'USD' }
      ]
    },
    {
      key: 'cogs',
      name: 'Cost of Goods Sold',
      subCategories: [
        { key: 'servers', name: 'Servers', value: 10000, type: 'number', unit: 'USD' },
        { key: 'apiServices', name: 'API Services', value: 5000, type: 'number', unit: 'USD' },
        { key: 'other', name: 'Other', value: 300, type: 'number', unit: 'USD' }
      ]
    },
    {
      key: 'operatingExpenses',
      name: 'Operating Expenses',
      subCategories: [
        { key: 'payroll', name: 'Payroll', value: 284092, type: 'number', unit: 'USD' },
        { key: 'contractLabor', name: 'Contract Labor', value: 8333, type: 'number', unit: 'USD' },
        { key: 'salesMarketingPrograms', name: 'Sales & Marketing Programs', value: 12500, type: 'number', unit: 'USD' },
        { key: 'generalAdministrative', name: 'General & Administrative', value: 6667, type: 'number', unit: 'USD' },
        { key: 'legalProfessionalFees', name: 'Legal & Professional Fees', value: 4167, type: 'number', unit: 'USD' },
        { key: 'rent', name: 'Rent', value: 10000, type: 'number', unit: 'USD' },
        { key: 'rdExpense', name: 'R&D Expense', value: 16667, type: 'number', unit: 'USD' },
        { key: 'travel', name: 'Travel', value: 5000, type: 'number', unit: 'USD' },
        { key: 'totalOperatingExpenses', name: 'Total Operating Expenses', value: 347426, type: 'number', unit: 'USD' }
      ]
    },
    // {
    //     key: 'financialMetrics',
    //     name: 'Financial Metrics',
    //     subCategories: [
    //         { key: 'interestIncomeExpense', name: 'Interest Income/Expense', value: -5000, type: 'number', unit: 'USD' },
    //         { key: 'netIncome', name: 'Net Income', value: 50000, type: 'number', unit: 'USD' },
    //         { key: 'netIncomeMargin', name: 'Net Income Margin', value: 0.169, type: 'percentage', unit: '%' },
    //         { key: 'operatingIncome', name: 'Operating Income', value: 55000, type: 'number', unit: 'USD' },
    //         { key: 'operatingMargin', name: 'Operating Margin', value: 0.186, type: 'percentage', unit: '%' },
    //         { key: 'taxesLicenses', name: 'Taxes & Licenses', value: 15000, type: 'number', unit: 'USD' }
    //     ]
    // },
    {
      key: 'cashBalance',
      name: 'Cash Balance',
      subCategories: [
        { key: 'openingCashBalance', name: 'Opening Cash Balance', value: 500000, type: 'number', unit: 'USD' },
        // { key: 'netIncome', name: 'Net Income', value: 50000, type: 'number', unit: 'USD' },
        { key: 'depreciation', name: 'Depreciation', value: 10000, type: 'number', unit: 'USD' },
        { key: 'capitalExpenditure', name: 'Capital Expenditure', value: 20000, type: 'number', unit: 'USD' },
        // { key: 'netCashFlow', name: 'Net Cash Flow', value: 40000, type: 'number', unit: 'USD' },
        { key: 'capitalRaise', name: 'Capital Raise', value: 0, type: 'number', unit: 'USD' }
        // { key: 'closingCashBalance', name: 'Closing Cash Balance', value: 540000, type: 'number', unit: 'USD' }
      ]
    }
  ]
};

const sampleFinancialModel = {
  subscriptions: [
    { id: 3, key: 'hobbyTier', name: 'Hobby Tier', cost: 19.99 },
    { id: 2, key: 'basicTier', name: 'Basic Tier', cost: 29.99 },
    { id: 1, key: 'proTier', name: 'Pro Tier', cost: 99.99 }
  ],
  monthlyFinancials: [
    initialMetrics,
    generateMonthlyMetrics(initialMetrics, growthRate, 2),
    generateMonthlyMetrics(initialMetrics, growthRate, 3),
    generateMonthlyMetrics(initialMetrics, growthRate, 4),
    generateMonthlyMetrics(initialMetrics, growthRate, 5),
    generateMonthlyMetrics(initialMetrics, growthRate, 6),
    generateMonthlyMetrics(initialMetrics, growthRate, 7),
    generateMonthlyMetrics(initialMetrics, growthRate, 8),
    generateMonthlyMetrics(initialMetrics, growthRate, 9),
    generateMonthlyMetrics(initialMetrics, growthRate, 10),
    generateMonthlyMetrics(initialMetrics, growthRate, 11),
    generateMonthlyMetrics(initialMetrics, growthRate, 12),
    generateMonthlyMetrics(initialMetrics, growthRate, 13),
    generateMonthlyMetrics(initialMetrics, growthRate, 14),
    generateMonthlyMetrics(initialMetrics, growthRate, 15),
    generateMonthlyMetrics(initialMetrics, growthRate, 16),
    generateMonthlyMetrics(initialMetrics, growthRate, 17),
    generateMonthlyMetrics(initialMetrics, growthRate, 18)
  ]
};

const initialState = {
  error: null,
  subscriptions: [],
  monthlyFinancials: []
};

//Reducer for the financialModel
const financialModel = createSlice({
  name: 'financialModel',
  initialState: initialState,
  reducers: {
    addSubscriptionSuccess(state, action) {
      state.subscriptions.push(action.payload);
      // subsChanges(state);
    },
    removeSubscriptionSuccess(state, action) {
      state.subscriptions = state.subscriptions.filter((sub) => sub.id !== action.payload);
      // subsChanges(state);
    },
    updateSubscriptionSuccess(state, action) {
      const index = state.subscriptions.findIndex((sub) => sub.id === action.payload.id);
      if (index !== -1) {
        state.subscriptions[index] = action.payload;
      }
      // subsChanges(state);
    },
    getFinancialModelSuccess(state, action) {
      return action.payload;
    },
    updateFinancialModelSuccess(state, action) {
      return action.payload;
    },
    updateMonthlyFinancialsSuccess(state, action) {
      state.monthlyFinancials = action.payload;
    }
  }
});

export default financialModel.reducer;

export const getFinancialModel = () => {
  console.log(`Get Financial Model`);
  return (dispatch, getState) => {
    const curFinancialModel = getState().financialModel;
    console.log(curFinancialModel);
    if (curFinancialModel.monthlyFinancials.length === 0) {
      sampleFinancialModel.monthlyFinancials = subsChanges(sampleFinancialModel);
      sampleFinancialModel.monthlyFinancials = calculateFinancials(sampleFinancialModel.monthlyFinancials);
      updateFinancialModel(sampleFinancialModel);
    } else {
      return curFinancialModel;
      // dispatch(financialModel.actions.getFinancialModelSuccess(curFinancialModel));
    }
  };
};

export const updateFinancialModel = (newFinancialModel) => {
  return dispatch(financialModel.actions.updateFinancialModelSuccess(newFinancialModel));
};

export function addSubscription(subscription) {
  console.log(`Add Subscription`);
  console.log(subscription);
  return dispatch(financialModel.actions.addSubscriptionSuccess(subscription));
  // updateMonthlyFinancials();
}

export const removeSubscription = (id) => {
  return dispatch(financialModel.actions.removeSubscriptionSuccess(id));
  // updateMonthlyFinancials();
};

export const updateSubscription = (subscription) => {
  return dispatch(financialModel.actions.updateSubscriptionSuccess(subscription));
  // updateMonthlyFinancials();
};

// export const updateMonthlyFinancials = () => {
//     return (dispatch, getState) => {
//         // const financialModel = getState().financialModel;
//         const updatedFinancials = calculateFinancials(getState().financialModel.monthlyFinancials);
//         dispatch(financialModel.actions.updateMonthlyFinancialsSuccess(updatedFinancials));
//     }
// }
