import { Box, FormControl, InputAdornment, OutlinedInput } from '@mui/material';
import { SearchOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const SearchInput = ({ onChange, placeholder, defaultValue }) => {
  const [text, setText] = useState(defaultValue || '');

  // delay 500ms because it is search input and we don't need to send request on every symbol
  // also will be reusable every time when we use SearchInput component
  useEffect(() => {
    if (onChange) {
      const timeOutId = setTimeout(() => onChange(text), 500);
      return () => clearTimeout(timeOutId);
    }
  }, [text]);

  return (
    <Box sx={{ width: '100%', ml: { xs: 0, md: 1 } }}>
      <FormControl sx={{ width: { xs: '100%', md: 224 } }}>
        <OutlinedInput
          defaultValue={text}
          onChange={(event) => setText(event.target.value)}
          size="small"
          id="header-search"
          startAdornment={
            <InputAdornment position="start" sx={{ mr: -0.5 }}>
              <SearchOutlined />
            </InputAdornment>
          }
          aria-describedby="header-search-text"
          inputProps={{
            'aria-label': 'weight'
          }}
          placeholder={placeholder || 'Search...'}
        />
      </FormControl>
    </Box>
  );
};

SearchInput.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string
};

export default SearchInput;
