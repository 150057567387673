import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

// Reducer for the headcount
// Tracks the divisions and positions of the company over time

/*
    fullTimeEmployee = {
        id: number,
        name: string,
        position: string,
        division: string,
        startDate: string,
        endDate: string,
        salary: number
    }
        
    contractor = {
        id: number,
        name: string,
        position: string,
        rate: pricing, // Data Structure for pricing (see product.js)
        hoursPerWeek: number,
        startDate: string,
        endDate: string,
    }

    headcount = {
        error: null,
        bonusPercentage: 0.1, // 10% of payroll to be used as a bonus pool
        payrollTax: 0.05, // 5% of payroll to be used for payroll taxes
        employeeBenefits: 0.05, // 5% of payroll to be used for employee benefits
        divisions: [],
        fullTimeEmployees: [],
        contractors: []
    }
*/
// Initial state
const initialState = {
  error: null,
  bonusPercentage: 0.1, // 10% of payroll to be used as a bonus pool
  payrollTax: 0.05, // 5% of payroll to be used for payroll taxes
  employeeBenefits: 0.05, // 5% of payroll to be used for employee benefits
  fullTimeEmployees: [],
  monthlyFullTimeEmployees: [],
  contractors: [],
  monthlyContractors: [],
  monthlyPayroll: [],
  roles: [
    { id: '1', name: 'Founders', averageSalary: 115000 },
    { id: '2', name: 'Customer Service', averageSalary: 50000 },
    { id: '3', name: 'Engineering', averageSalary: 120000 }
  ],
  headcountData: [
    { roleId: '1', month: `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}`, count: 2 } // 2 founders
  ],
  additionalExpenseTypes: [{ id: '1', name: 'Legal Fees', type: 'fixed', value: 500 }],
  additionalExpensesData: []
};

// Slice
const headcount = createSlice({
  name: 'headcount',
  initialState,
  reducers: {
    addRole: (state, action) => {
      state.roles.push({
        id: uuidv4(),
        name: action.payload.name,
        averageSalary: action.payload.averageSalary
      });
    },
    deleteRole: (state, action) => {
      state.roles = state.roles.filter((role) => role.id !== action.payload);
      state.headcountData = state.headcountData.filter((data) => data.roleId !== action.payload);
    },
    updateRole: (state, action) => {
      const index = state.roles.findIndex((role) => role.id === action.payload.id);
      if (index !== -1) {
        state.roles[index] = action.payload;
      }
    },
    updateHeadcount: (state, action) => {
      const existingIndex = state.headcountData.findIndex(
        (data) => data.roleId === action.payload.roleId && data.month === action.payload.month
      );
      if (existingIndex !== -1) {
        state.headcountData[existingIndex] = action.payload;
      } else {
        state.headcountData.push(action.payload);
      }
    },

    // Additional Expense Types and Expenses Reducers
    addExpenseType: (state, action) => {
      state.additionalExpenseTypes.push({
        id: uuidv4(),
        name: action.payload.name,
        type: action.payload.type,
        value: action.payload.value
      });
    },
    updateExpenseType: (state, action) => {
      const index = state.additionalExpenseTypes.findIndex((expenseType) => expenseType.id === action.payload.id);
      if (index !== -1) {
        state.additionalExpenseTypes[index] = action.payload;
      }
    },
    deleteExpenseType: (state, action) => {
      state.additionalExpenseTypes = state.additionalExpenseTypes.filter((expenseType) => expenseType.id !== action.payload);
      state.additionalExpensesData = state.additionalExpensesData.filter((expense) => expense.expenseTypeId !== action.payload);
    },
    addOrUpdateExpense: (state, action) => {
      const { expenseTypeId, month, cost } = action.payload;
      const existingIndex = state.additionalExpensesData.findIndex(
        (expense) => expense.expenseTypeId === expenseTypeId && expense.month === month
      );
      if (existingIndex !== -1) {
        state.additionalExpensesData[existingIndex].cost = cost;
      } else {
        state.additionalExpensesData.push({ expenseTypeId, month, cost });
      }
    },
    deleteExpense: (state, action) => {
      const { expenseTypeId, month } = action.payload;
      state.additionalExpensesData = state.additionalExpensesData.filter(
        (expense) => !(expense.expenseTypeId === expenseTypeId && expense.month === month)
      );
    }
  }
});

// Export actions and reducer
export const {
  addRole,
  deleteRole,
  updateRole,
  updateHeadcount,
  addExpenseType,
  updateExpenseType,
  deleteExpenseType,
  addOrUpdateExpense,
  deleteExpense
} = headcount.actions;

export default headcount.reducer;
