import { get, post, put, destroy } from 'utils/axios';

/* Basic CRUD:
index  <- GET all items
single <- GET single item by id
create <- POST an item
update <- PUT data to an item
remove <- DELETE an item
Specific:
singleByEmail
removeAll */

export const industryEndpoints = {
  baseUrl: `/industries`
};

export const Industries = {
  index: () => get(`${industryEndpoints.baseUrl}/`),
  single: (id) => get(`${industryEndpoints.baseUrl}/${id}/`),
  create: (params) => post(`${industryEndpoints.baseUrl}/`, params),
  update: (id, params) => put(`${industryEndpoints.baseUrl}/${id}/`, params),
  remove: (id) => destroy(`${industryEndpoints.baseUrl}/${id}/`)
};

export const domainEndpoints = {
  baseUrl: `/domains`
};

export const Domains = {
  index: () => get(`${domainEndpoints.baseUrl}/`),
  single: (name) => get(`${domainEndpoints.baseUrl}/${name}/`),
  create: (params) => post(`${domainEndpoints.baseUrl}/`, params),
  update: (name, params) => put(`${domainEndpoints.baseUrl}/${name}/`, params),
  remove: (name) => destroy(`${domainEndpoints.baseUrl}/${name}/`),
  exists: (name) => {
    if (name) {
      return get(`${domainEndpoints.baseUrl}/${name}/exists/`);
    }
    throw new Error('Cannot check for domain existing without a name');
  }
};

export const companyEndpoints = {
  baseUrl: `/companies`
};

export const Companies = {
  index: () => get(`${companyEndpoints.baseUrl}/`), // Need to be superuser
  single: (uuid) => get(`${companyEndpoints.baseUrl}/${uuid}/`),
  create: (params) => {
    let fmtd = handleAddress(params);
    return post(`${companyEndpoints.baseUrl}/`, fmtd);
  },
  update: (uuid, params) => {
    let fmtd = handleAddress(params);
    if (fmtd.logo || fmtd.bgImage) {
      console.log('sending image data...');
      // let formData = new FormData();
      // formData.append('test', 'TESTING');
      // formData.append('logo', fmtd.logo, fmtd.logo.name);
      // formData.append('logo', new Blob(fmtd.logo, { type: fmtd.logo.type}), fmtd.logo.name);
      // console.log(formData);
      // console.log(formData.get('logo'));
      return put(`${companyEndpoints.baseUrl}/${uuid}/`, fmtd, {
        headers: {
          // 'accept': 'application/json',
          // 'Accept-Language': 'en-US,en;q=0.8',
          'Content-Type': `multipart/form-data`
        }
      }); // Can only be your own, unless superuser
    }
    return put(`${companyEndpoints.baseUrl}/${uuid}/`, fmtd); // Can only be your own, unless superuser
  },
  remove: (uuid) => destroy(`${companyEndpoints.baseUrl}/${uuid}/`),
  mine: () => get(`${companyEndpoints.baseUrl}/mine/`),
  byDomain: (domain) => get(`${companyEndpoints.baseUrl}/${domain}/by_domain/`)
};

const handleAddress = (params) => {
  // Function to handle converting from a flat object to a tiered object with a
  // separate address sub-object
  console.log(params);
  let data = {};
  const {
    address,
    address1,
    address2,
    formatted,
    streetNumber,
    route,
    postalCode,
    county,
    stateShort,
    city,
    country,
    countryShort,
    latitude,
    longitude,
    ...rest
  } = params;
  if (address1 && typeof address !== 'object') {
    // address is a string, transition to an object with fields
    const structuredAddress = {
      raw: formatted,
      formatted,
      streetNumber,
      route,
      postalCode,
      state: county,
      stateShort,
      city,
      country,
      countryShort,
      latitude,
      longitude
    };
    data = {
      address: structuredAddress,
      ...rest
    };
  } else if (address) {
    data = {
      address: address,
      ...rest
    };
  } else {
    data = params;
  }

  console.debug(address1, address2); // just get rid of stupid non-use errors
  console.log(data);
  return data;
};
