// import { de } from 'date-fns/locale';

// Types
// type Product = {
//     id: string;
//     name: string;
//     price: number;
// };

// type SalesData = {
//     productId: string;
//     month: string;
//     sales: number;
// };

// type HeadcountData = {
//     roleId: string;
//     month: string;
//     count: number;
// };

// type Role = {
//     id: string;
//     name: string;
//     averageSalary: number;
// };

// type ProductExpense = {
//     productId: string;
//     expenseTypeId: string;
//     cost: number;
// };

// type GeneralExpense = {
//     id: string;
//     name: string;
//     type: 'fixed' | 'percentage' | 'per_employee';
//     value: number;
// };

// type FinancialData = {
//     products: Product[];
//     salesData: SalesData[];
//     headcountData: HeadcountData[];
//     roles: Role[];
//     productExpenses: ProductExpense[];
//     generalExpenses: GeneralExpense[];
// };

// type MonthlyFinancials = {
//     month: string;
//     revenue: number;
//     cogs: number;
//     grossProfit: number;
//     grossMargin: number;
//     operatingExpenses: number;
//     netProfit: number;
//     netMargin: number;
// };

// Helper function to get all unique months from sales data
function getUniqueMonths(salesData) {
  return Array.from(new Set(salesData.map((sale) => sale.month))).sort();
}

// Calculate total revenue for a given month
function calculateMonthlySales(salesData, products, month) {
  if (salesData === undefined || products === undefined) return 0;
  const monthlySales = salesData.filter((sale) => sale.month === month);

  const monthlySalesData = {
    key: 'unitsSold',
    name: 'Units Sold',
    subCategories: []
  };
  monthlySalesData.subCategories = monthlySales.map((sale) => {
    const product = products.find((p) => p.uuid === sale.productId);
    if (product) {
      return { key: sale.productId, name: product.name, value: sale.sales, type: 'number', unit: 'ea' };
    }
    return undefined;
  });
  monthlySalesData.subCategories = monthlySalesData.subCategories.filter((sc) => sc !== undefined);

  const total = monthlySales.reduce((total, sale) => {
    const product = products.find((p) => p.uuid === sale.productId);
    return total + (product ? product.price * sale.sales : 0);
  }, 0);

  monthlySalesData.subCategories.push({ key: 'total', name: 'Total', value: total, type: 'number', unit: 'USD' });
  return monthlySalesData;
}

// Calculate total revenue for a given month
function calculateMonthlyRevenue(salesData, products, month) {
  if (salesData === undefined || products === undefined) return 0;
  const monthlySales = salesData.filter((sale) => sale.month === month);

  const monthlySalesData = {
    key: 'revenue',
    name: 'Revenue',
    subCategories: []
  };
  monthlySalesData.subCategories = monthlySales.map((sale) => {
    const product = products.find((p) => p.uuid === sale.productId);
    const revenue = sale.sales * product.price;
    return { key: sale.productId, name: product.name, value: revenue, type: 'number', unit: 'USD' };
  });

  const total = monthlySales.reduce((total, sale) => {
    const product = products.find((p) => p.uuid === sale.productId);
    return total + (product ? product.price * sale.sales : 0);
  }, 0);

  monthlySalesData.subCategories.push({ key: 'total', name: 'Total', value: total, type: 'number', unit: 'USD' });
  return monthlySalesData;
}

// Calculate COGS for a given month
function calculateMonthlyCOGS(salesData, product, month) {
  const { expenseTypes, productExpenses } = product;

  if (salesData === undefined || salesData.length === 0 || productExpenses === undefined || productExpenses.length === 0) {
    const monthlyCogsData = {
      key: 'cogs',
      name: 'Cost of Goods Sold',
      subCategories: []
    };
    console.log('productExpenses');
    console.log(productExpenses);
    monthlyCogsData.subCategories = expenseTypes.map((expenseType) => {
      return { key: expenseType.id, name: expenseType.name, value: 0, type: 'number', unit: 'USD' };
    });

    monthlyCogsData.subCategories.push({ key: 'total', name: 'Total', value: 0, type: 'number', unit: 'USD' });
    return monthlyCogsData;
  }

  const monthlyCOGS = salesData.filter((sale) => sale.month === month);

  const monthlyCogsData = {
    key: 'cogs',
    name: 'Cost of Goods Sold',
    subCategories: []
  };

  monthlyCogsData.subCategories = monthlyCOGS.map((cogs) => {
    const expenseType = expenseTypes.find((et) => et.id === cogs.expenseTypeId);
    const expense = { key: expenseType.id, name: expenseType.name, value: cogs.cost, type: 'number', unit: 'USD' };
    return expense;
  });
  const total = monthlyCOGS.reduce((total, expenseItem) => {
    const expenses = productExpenses.filter((pe) => pe.productId === expenseItem.productId);
    const expenseTotal = expenses.reduce((sum, expense) => sum + expense.cost, 0);

    return total + expenseTotal;
  }, 0);

  monthlyCogsData.subCategories.push({ key: 'total', name: 'Total', value: total, type: 'number', unit: 'USD' });
  return monthlyCogsData;
}

// Calculate total headcount for a given month
function calculateMonthlyHeadcount(headcountData, month) {
  if (headcountData === undefined) return 0;

  return headcountData.filter((data) => data.month === month).reduce((total, data) => total + data.count, 0);
}

// Calculate total salary expenses for a given month
function calculateMonthlySalaryExpenses(headcountData, roles, month) {
  if (headcountData === undefined || headcountData.length === 0) {
    const monthlySalaryExpenses = {
      key: 'payroll',
      name: 'Payroll',
      subCategories: []
    };
    monthlySalaryExpenses.subCategories = roles.map((role) => {
      return { key: role.id, name: role.name, value: 0, type: 'number', unit: 'USD' };
    });

    monthlySalaryExpenses.subCategories.push({ key: 'total', name: 'Total', value: 0, type: 'number', unit: 'USD' });
    return monthlySalaryExpenses;
  }

  const monthHeadcount = headcountData.filter((data) => data.month === month);

  const monthlySalaryExpenses = {
    key: 'payroll',
    name: 'Payroll',
    subCategories: []
  };
  console.log('monthHeadcount');
  console.log(monthHeadcount);

  monthlySalaryExpenses.subCategories = roles.map((role) => {
    const headcountExpense = headcountData.find((r) => r.roleId === role.id);
    const roleExpense = {
      key: role.id,
      name: role.name,
      value: headcountExpense ? role.averageSalary * headcountExpense.count : 0,
      type: 'number',
      unit: 'USD'
    };

    return roleExpense;
  });

  const total = monthlySalaryExpenses.subCategories.reduce((total, sc) => total + sc.value, 0);
  monthlySalaryExpenses.subCategories.push({ key: 'total', name: 'Total', value: total, type: 'number', unit: 'USD' });
  return monthlySalaryExpenses;
}

// Calculate general expenses for a given month
function calculateMonthlyGeneralExpenses(
  generalExpenses, //: GeneralExpense[],
  generalExpenseTypes, //: ExpenseType[],
  revenue, //: number,
  totalHeadcount //: number
) {
  if (generalExpenseTypes === undefined || generalExpenseTypes.length === 0) return undefined;

  // if (generalExpenses === undefined || generalExpenses.length === 0) {
  //     const monthExpenses = {
  //         key: 'generalExpenses', name: 'General Expenses', subCategories: []
  //     };
  //     monthExpenses.subCategories = generalExpenseTypes.map(expense => {
  //         return { key: expense.id, name: expense.name, value: 0, type: 'number', unit: 'USD' };
  //     });
  //     monthExpenses.subCategories.push({ key: 'total', name: 'Total', value: 0, type: 'number', unit: 'USD' });
  //     return monthExpenses;
  // }

  const monthExpenses = {
    key: 'generalExpenses',
    name: 'Other Operating Expenses',
    subCategories: []
  };
  console.log('generalExpenseTypes');
  console.log(generalExpenseTypes);

  monthExpenses.subCategories = generalExpenseTypes.map((expense) => {
    switch (expense.type) {
      case 'fixed':
        return { key: expense.id, name: expense.name, value: expense.value, type: 'number', unit: 'USD' };
      case 'percentage':
        return { key: expense.id, name: expense.name, value: (revenue * expense.value) / 100, type: 'number', unit: 'USD' };
      case 'per_employee':
        return { key: expense.id, name: expense.name, value: expense.value * totalHeadcount, type: 'number', unit: 'USD' };
      default:
        return { key: expense.id, name: expense.name, value: 0, type: 'number', unit: 'USD' };
    }
  });

  const total = monthExpenses.subCategories.reduce((total, expense) => total + expense.value, 0);
  monthExpenses.subCategories.push({ key: 'total', name: 'Total', value: total, type: 'number', unit: 'USD' });
  return monthExpenses;
}

function calculateMonthlyOperatingTotals(revenue, cogs, salaryExpenses, generalExpenses) {
  const operatingTotals = {
    key: 'operatingTotals',
    name: 'Operating Totals',
    subCategories: []
  };
  // Gross Profit = Revenue - COGS
  const grossProfit = revenue - cogs;
  //   const grossProfitMargin = revenue > 0 ? (grossProfit / revenue) * 100 : 0;

  // Operating expenses = Marketing and Sales + Development + General and Administrative
  const totalOperatingExpenses =
    (salaryExpenses?.subCategories.find((sc) => sc.key === 'total').value ?? 0) +
    (generalExpenses?.subCategories.find((sc) => sc.key === 'total').value ?? 0);

  operatingTotals.subCategories.push({ key: 'total', name: 'Total', value: totalOperatingExpenses, type: 'number', unit: 'USD' });

  // Operating Income = Gross Profit - Operating Expenses
  const operatingIncome = grossProfit - totalOperatingExpenses;
  const operatingMargin = revenue > 0 ? (operatingIncome / revenue) * 100 : 0;
  console.log(`Operating Expenses Total = ${totalOperatingExpenses}`);
  console.log(`Gross Profit = ${grossProfit}`);
  console.log(`Operating Income = ${operatingIncome}`);
  console.log(`Operating Margin = ${operatingMargin}`);

  operatingTotals.subCategories.push({
    key: 'operatingIncome',
    name: 'Operating Income',
    value: operatingIncome,
    type: 'number',
    unit: 'USD'
  });
  operatingTotals.subCategories.push({
    key: 'operatingMargin',
    name: 'Operating Margin',
    value: operatingMargin,
    type: 'percentage',
    unit: '%'
  });

  // TODO add depreciation and taxes
  const depreciation = 0;
  const taxes = 0;
  const netIncome = operatingIncome - depreciation - taxes;
  const netMargin = revenue > 0 ? (netIncome / revenue) * 100 : 0;

  operatingTotals.subCategories.push({ key: 'netIncome', name: 'Net Income', value: netIncome, type: 'number', unit: 'USD' });
  operatingTotals.subCategories.push({ key: 'netMargin', name: 'Net Margin', value: netMargin, type: 'percentage', unit: '%' });

  return operatingTotals;
}

// Main function to calculate monthly financials
export function calculateMonthlyFinancials(data) {
  console.log('calculateMonthlyFinancials');
  console.log(data);
  const months = getUniqueMonths(data.sales.salesData);
  console.log('Months');
  console.log(months);

  const monthlyData = months.map((month) => {
    const sales = calculateMonthlySales(data.sales.salesData, data.product.products, month);
    console.log(`Sales = ${sales}`);
    const revenue = calculateMonthlyRevenue(data.sales.salesData, data.product.products, month);
    console.log(`Revenue = ${revenue}`);
    const cogs = calculateMonthlyCOGS(data.sales.salesData, data.product, month);
    console.log(`COGS = ${cogs}`);
    const revenueTotal = sales.subCategories.find((sc) => sc.key === 'total').value;
    const cogsTotal = cogs.subCategories.find((sc) => sc.key === 'total').value;
    const grossProfit = revenueTotal - cogsTotal;
    const grossMargin = revenueTotal > 0 ? (grossProfit / revenueTotal) * 100 : 0;

    const grossMargins = {
      key: 'grossMargins',
      name: 'Gross Margins',
      subCategories: []
    };
    grossMargins.subCategories.push({ key: 'grossProfit', name: 'Gross Profit', value: grossProfit, type: 'number', unit: 'USD' });
    grossMargins.subCategories.push({ key: 'grossMargin', name: 'Gross Margin', value: grossMargin, type: 'percentage', unit: '%' });

    const headcount = calculateMonthlyHeadcount(data.headcount.headcountData, month);
    const salaryExpenses = calculateMonthlySalaryExpenses(data.headcount.headcountData, data.headcount.roles, month);
    const generalExpenses = calculateMonthlyGeneralExpenses(
      data.headcount.additionalExpensesData,
      data.headcount.additionalExpenseTypes,
      revenueTotal,
      headcount
    );

    const operatingTotals = calculateMonthlyOperatingTotals(revenueTotal, cogsTotal, salaryExpenses, generalExpenses);

    const netProfit = grossProfit - operatingTotals.subCategories.find((sc) => sc.key === 'total').value;
    const netMargin = revenue > 0 ? (netProfit / revenue) * 100 : 0;

    return {
      month,
      sales,
      revenue,
      cogs,
      grossMargins,
      salaryExpenses,
      generalExpenses,
      operatingTotals,
      grossProfit,
      netProfit,
      netMargin
    };
  });

  monthlyData.map((month, index) => {
    if (index === 0) return;
    month;
    // const prevMonth = monthlyData[index - 1];
    // month.revenueGrowthRate = (month.revenue - prevMonth.revenue) / prevMonth.revenue;
  });
  return monthlyData;
}

// Function to calculate totals across all months
function calculateTotals(monthlyFinancials) {
  const totals = monthlyFinancials.reduce(
    (acc, curr) => ({
      revenue: acc.revenue + curr.revenue,
      cogs: acc.cogs + curr.cogs,
      grossProfit: acc.grossProfit + curr.grossProfit,
      operatingExpenses: acc.operatingExpenses + curr.operatingExpenses,
      netProfit: acc.netProfit + curr.netProfit
    }),
    { revenue: 0, cogs: 0, grossProfit: 0, operatingExpenses: 0, netProfit: 0 }
  );

  return {
    month: 'Total',
    ...totals,
    grossMargin: totals.revenue > 0 ? (totals.grossProfit / totals.revenue) * 100 : 0,
    netMargin: totals.revenue > 0 ? (totals.netProfit / totals.revenue) * 100 : 0
  };
}

// Main function to generate the complete financial report
export function generateFinancialReport(data) {
  const monthlyFinancials = calculateMonthlyFinancials(data);
  const totals = calculateTotals(monthlyFinancials);

  const financialReport = monthlyFinancials.map((month) => {
    let metrics = [
      month.sales,
      month.revenue,
      month.cogs,
      month.grossMargins,
      month.salaryExpenses,
      month.generalExpenses,
      month.operatingTotals,
      month.grossProfit,
      month.netProfit
    ];
    metrics = metrics.filter((m) => m !== undefined);

    return {
      endDate: month.month,
      metrics: metrics
    };
  });
  return financialReport;
}

export function generateFinancialReportWithGrowthRates(data) {
  const monthlyFinancials = calculateMonthlyFinancials(data);
  const totals = calculateTotals(monthlyFinancials);
  return [...monthlyFinancials, totals];
}

function addMonths(date, months) {
  const newDate = new Date(date); // Create a copy of the date
  newDate.setMonth(newDate.getMonth() + months);
  return newDate;
}

export const calculateFinancials = (data) => {
  const newData = _.cloneDeep(data);

  newData.forEach((month, index) => {
    const unitsGrowthRates = [];
    const unitsSoldSubCategories = [];
    if (index === 0) {
      month.metrics
        .find((m) => m.key === 'unitsSold')
        .subCategories.forEach((sc) => {
          unitsSoldSubCategories.push(sc);

          const growthRate = { key: `${sc.key}GrowthRate`, name: `% change`, value: 0, type: 'percentage', unit: '%' };
          unitsSoldSubCategories.push(growthRate);

          unitsGrowthRates.push({ key: 'unitsSold', subCategory: `${sc.key}GrowthRate`, value: 0 });
        });
    } else {
      month.metrics
        .find((m) => m.key === 'unitsSold')
        .subCategories.forEach((sc) => {
          const prevMonth = newData[index - 1].metrics.find((m) => m.key === 'unitsSold').subCategories.find((psc) => psc.key === sc.key);
          const change = (sc.value - prevMonth.value) / prevMonth.value;
          unitsSoldSubCategories.push(sc);

          const growthRate = { key: `${sc.key}GrowthRate`, name: `% change`, value: change, type: 'percentage', unit: '%' };
          unitsSoldSubCategories.push(growthRate);

          unitsGrowthRates.push({ key: 'unitsSold', subCategory: sc.key, value: change });
        });
    }
    month.metrics.find((m) => m.key === 'unitsSold').subCategories = unitsSoldSubCategories;
    console.log('unitsGrowthRates');
    console.log(unitsGrowthRates);

    // Calculate Revenue
    const revenueTotals = [];
    const revenueGrowthRates = [];
    month.metrics.splice(2, 0, { key: 'revenue', name: 'Revenue', subCategories: [] });
    month.metrics
      .find((m) => m.key === 'unitsSold')
      .subCategories.forEach((sc, revIndex) => {
        if (sc.key.endsWith('GrowthRate')) return;
        const prevMonthIndex = revIndex == 0 ? 0 : 2 * revIndex - 1;
        const prevMonthRevenue = index == 0 ? 0 : newData[index - 1].metrics.find((m) => m.key === 'revenue').subCategories[prevMonthIndex];
        const revenue = sc.value * month.metrics.find((m) => m.key === 'pricing')?.subCategories.find((sc) => sc.key === sc.key).value;
        const growthRate = (revenue - prevMonthRevenue) / prevMonthRevenue;

        revenueTotals.push({ key: `${sc.key}Revenue`, subCategory: `${sc.key} Total Rev`, value: revenue });
        revenueGrowthRates.push({ key: 'revenue', subCategory: sc.key, value: growthRate });

        month.metrics
          .find((m) => m.key === 'revenue')
          ?.subCategories.push({ key: `${sc.key}Revenue`, name: `${sc.name} Rev`, value: revenue, type: 'number', unit: 'USD' });
        // month.metrics.find(m => m.key === 'revenue')?.subCategories.push({ key: `${sc.key}GrowthRate`, name: '% change', value: growthRate, type: 'percentage', unit: '%' });
      });
    const monthlyRevenueTotal = revenueTotals.reduce((acc, sc) => acc + sc.value, 0);
    const prevMonthlyRevenueTotal =
      index == 0 ? 0 : newData[index - 1].metrics.find((m) => m.key === 'revenue').subCategories.find((sc) => sc.key === 'total').value;

    const revenueGrowthRate = index == 0 ? 0 : (monthlyRevenueTotal - prevMonthlyRevenueTotal) / prevMonthlyRevenueTotal;
    month.metrics
      .find((m) => m.key === 'revenue')
      ?.subCategories.push({ key: 'total', name: 'Total', value: monthlyRevenueTotal, type: 'number', unit: 'USD' });
    month.metrics
      .find((m) => m.key === 'revenue')
      ?.subCategories.push({ key: 'totalGrowthRate', name: '% change', value: revenueGrowthRate, type: 'percentage', unit: '%' });

    console.log('Revenue Totals');
    console.log(revenueTotals);

    console.log('Revenue Growth Rates');
    console.log(revenueGrowthRates);

    // const cogsIndex = month.metrics.findIndex(m => m.key === 'cogs');
    let totalCogs = month.metrics
      .find((m) => m.key === 'cogs')
      ?.subCategories.reduce((acc, sc) => (sc.type === 'number' ? acc + sc.value : acc), 0);
    totalCogs = totalCogs ? totalCogs : 0;

    // month.metrics.splice(cogsIndex + 1, 0, { key: 'cogsTotals', name: 'Cogs Totals', subCategories: [] })
    month.metrics
      .find((m) => m.key === 'cogs')
      ?.subCategories.push({ key: 'total', name: 'Total', value: totalCogs, type: 'number', unit: 'USD' });

    // Gross Profit = Revenue - COGS
    const grossProfit = monthlyRevenueTotal - totalCogs;
    const grossProfitMargin = (grossProfit / monthlyRevenueTotal) * 100;

    // Operating expenses = Marketing and Sales + Development + General and Administrative
    const totalOperatingExpenses = month.metrics
      .find((m) => m.key === 'operatingExpenses')
      ?.subCategories.reduce((acc, sc) => (sc.type === 'number' ? acc + sc.value : acc), 0);
    month.metrics
      .find((m) => m.key === 'operatingExpenses')
      ?.subCategories.push({ key: 'total', name: 'Total', value: totalOperatingExpenses, type: 'number', unit: 'USD' });

    // Operating Income = Gross Profit - Operating Expenses
    const operatingIncome = grossProfit - totalOperatingExpenses;
    const operatingMargin = (operatingIncome / monthlyRevenueTotal) * 100;
    console.log(`Operating Expenses Total = ${totalOperatingExpenses}`);
    console.log(`Gross Profit = ${grossProfit}`);
    console.log(`Operating Income = ${operatingIncome}`);
    console.log(`Operating Margin = ${operatingMargin}`);

    const operatingExpensesIndex = month.metrics.findIndex((m) => m.key === 'operatingExpenses');
    month.metrics.splice(operatingExpensesIndex + 1, 0, { key: 'operatingTotals', name: 'Operating Totals', subCategories: [] });
    month.metrics
      .find((m) => m.key === 'operatingTotals')
      ?.subCategories.push({ key: 'total', name: 'Total', value: operatingIncome, type: 'number', unit: 'USD' });
    month.metrics
      .find((m) => m.key === 'operatingTotals')
      ?.subCategories.push({ key: 'operatingMargin', name: 'Operating Margin', value: operatingMargin, type: 'percentage', unit: '%' });

    // Net Income = Operating Income - Depreciation - Interest - Taxes
    let depreciation = month.metrics.find((m) => m.key === 'depreciation')?.subCategories.find((sc) => sc.key === 'total')?.value;
    let interest = month.metrics.find((m) => m.key === 'interest')?.subCategories.find((sc) => sc.key === 'total')?.value;
    let taxes = month.metrics.find((m) => m.key === 'taxes')?.subCategories.find((sc) => sc.key === 'total')?.value;

    depreciation = depreciation ? depreciation : 0;
    interest = interest ? interest : 0;
    taxes = taxes ? taxes : 0;

    console.log(`Depreciation = ${depreciation}`);
    console.log(`Interest = ${interest}`);
    console.log(`Taxes = ${taxes}`);

    const netIncome = operatingIncome - depreciation - interest - taxes;
    const netIncomeMargin = (netIncome / operatingIncome) * 100;

    console.log(`Net Income = ${netIncome}`);
    console.log(`Net Income Margin = ${netIncomeMargin}`);

    month.metrics
      .find((m) => m.key === 'cashBalance')
      ?.subCategories.push({ key: 'netIncome', name: 'Net Income', value: netIncome, type: 'number', unit: 'USD' });
    month.metrics
      .find((m) => m.key === 'cashBalance')
      ?.subCategories.push({ key: 'netIncomeMargin', name: 'Net Income Margin', value: netIncomeMargin, type: 'percentage', unit: '%' });

    const totalAssets = newData.cash + newData.fixedAssets - newData.accumulatedDepreciation;
    const totalLiabilities = newData.loan1 + newData.loan2;
    const accumulatedRetainedEarnings = netIncome; // This is simplified; in reality, it would be cumulative over time
    const totalEquity = newData.investments - newData.ownerWithdrawals + accumulatedRetainedEarnings;
    const totalLiabilitiesAndEquity = totalLiabilities + totalEquity;

    const totalOperatingCashFlows = netIncome + newData.depreciation;
    const changeInCash = totalOperatingCashFlows; // Simplified; should include investing and financing cash flows
    const endingCash = newData.beginningCash + changeInCash;
  });

  return newData;
};

export const payrollAndBenefits = (data, growthRate, months) => {
  const employeeCosts = [];
};

export const operatingExpenses = (data, growthRate, months) => {
  const operatingExpenses = [];
};

export const generateCashFlows = (data, growthRate, months) => {
  const cashFlows = [];
  const beginningCash = 0;
  const endingCash = 0;
  const changeInCash = 0;

  return {
    beginningCash,
    endingCash,
    changeInCash
  };
};

export const generateMonthlyMetrics = (data, growthRate, months) => {
  const monthlyMetrics = [];

  // Helper function to apply growth rate to a value over multiple months
  function applyGrowthRate(value, growthRate, months) {
    const values = [];
    for (let i = 0; i < months; i++) {
      values.push(value);
      value = value * (1 + growthRate); // Increase by growth rate
    }
    return values.at(-1);
  }

  // Iterate over each metric category
  data.metrics.forEach((metric) => {
    const monthlyMetric = {
      key: metric.key,
      name: metric.name,
      subCategories: []
    };

    // Iterate over each sub-category in the metric
    metric.subCategories.forEach((subCategory) => {
      const { value } = subCategory;

      // Generate an array of monthly values for the sub-category
      const monthlyValues = applyGrowthRate(value, growthRate, months);

      monthlyMetric.subCategories.push({
        key: subCategory.key,
        name: subCategory.name,
        value: monthlyValues, // Store the monthly values here
        type: subCategory.type,
        unit: subCategory.unit
      });
    });

    monthlyMetrics.push(monthlyMetric);
  });
  const newDate = new Date(data.endDate);
  const endDate = addMonths(newDate, months);
  return {
    endDate:
      String(endDate.getMonth() + 1).padStart(2, '0') + '-' + String(endDate.getDate()).padStart(2, '0') + '-' + endDate.getFullYear(),
    metrics: monthlyMetrics
  };
};

//Updates the monthlyFinancials based on the subscriptions
export const subsChanges = (financialModel) => {
  const { subscriptions, monthlyFinancials } = financialModel;
  console.log(subscriptions);
  monthlyFinancials.map((value, index) => {
    value.metrics.map((metric, index) => {
      if (metric.key === 'pricing') {
        metric.subCategories = [];
        subscriptions.map((subValue, subIndex) => {
          metric.subCategories.push({
            key: subValue.name.toLowerCase().replace(' ', ''),
            name: subValue.name,
            value: subValue.cost,
            type: 'number',
            unit: 'USD'
          });
        });
      }
      if (metric.key === 'unitsSold') {
        metric.subCategories = metric.subCategories.filter((sc) => subscriptions.find((sub) => sub.name === sc.name));
      }
    });
  });
  return monthlyFinancials;
};
