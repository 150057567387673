// third-party
import {
  //createAsyncThunk,
  createSlice
} from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';
import { Users } from 'apis/backend/services/User';

// ----------------------------------------------------------------------

const initialState = {
  members: [],
  membersError: null,
  membersPulled: false,
  membersReinvited: []
};

const team = createSlice({
  name: 'team',
  initialState,
  reducers: {
    // HAS ERROR
    hasMembersError(state, action) {
      state.membersError = action.payload;
    },
    // CLEAR ERROR
    clearMembersError(state) {
      state.membersError = null;
    },

    // GET MEMBERS
    getMembersSuccess(state, action) {
      state.members = action.payload;
      state.membersPulled = true;
    },

    // INVITE NEW MEMBERS
    inviteMemberSuccess(state, action) {
      state.members.push(action.payload);
    },

    // REINVITE MEMBER
    reinviteMemberSuccess(state, action) {
      const userId = action.payload;
      state.membersReinvited = [userId, ...state.membersReinvited];
    },

    // UPDATE MEMBERS
    updateMemberSuccess(state, action) {
      state.members = action.payload;
    },

    // DELETE MEMBERS
    deleteMemberSuccess(state) {
      state.members = null;
    }
  }
});

// Reducer
export default team.reducer;

// ----------------------------------------------------------------------
export function clearMembersError() {
  return dispatch(team.actions.clearMembersError());
}

export function getMembers() {
  return async () => {
    try {
      const response = await Users.team();
      dispatch(team.actions.getMembersSuccess(response.data));
    } catch (error) {
      dispatch(team.actions.hasMembersError(error));
    }
  };
}

export function inviteMember(email) {
  return async () => {
    try {
      const response = await Users.invite(email); // API to create element
      console.log(response);
      dispatch(team.actions.inviteMemberSuccess(response.data)); // Dispatch resulting Members obj
    } catch (error) {
      console.log(error);
      dispatch(team.actions.hasMembersError(error));
    }
  };
}

export function reinviteMember(userId) {
  return async () => {
    try {
      const response = await Users.reinvite(userId); // API to create element
      console.log(response);
      if (response.data.status === 'success') {
        dispatch(team.actions.reinviteMemberSuccess(userId)); // Dispatch
      }
    } catch (error) {
      console.log(error);
      dispatch(team.actions.hasMembersError(error));
    }
  };
}

export function updateMember(uuid, formValues) {
  return async (dispatch, getState) => {
    try {
      const response = await Users.update(uuid, formValues); // API to update element
      console.log(response);
      dispatch(team.actions.updateMemberSuccess(response.data)); // Dispatch resulting Members obj
    } catch (error) {
      console.log(error);
      dispatch(team.actions.hasMembersError(error));
      console.log(getState());
    }
  };
}

export function deleteMember(uuid) {
  return async () => {
    try {
      const response = await Users.remove(uuid); // API to delete element
      console.log(response);
      dispatch(team.actions.deleteMemberSuccess(uuid)); // Dispatch cat to delete
    } catch (error) {
      dispatch(team.actions.hasMembersError(error));
    }
  };
}
