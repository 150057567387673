// third-party
import { combineReducers } from 'redux';

// project import
import menu from './menu';
import snackbar from './snackbar';
import company from './company';
import team from './team';
import product from './product';
import sales from './sales';
import financialModel from './financialModel';
import headcount from './headcount';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  snackbar,
  product,
  company,
  team,
  financialModel,
  sales,
  headcount
});

export default reducers;
