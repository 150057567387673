import React, { useEffect, useState, useRef } from 'react';
// import './App.css';
import ReactGA from 'react-ga4';

// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';

import Loader from 'components/Loader';
import Locales from 'components/Locales';
import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop';
import Snackbar from 'components/common/@extended/Snackbar';
import Notistack from 'components/third-party/Notistack';

import { dispatch } from 'store';
import { fetchMenu } from 'store/reducers/menu';

// auth provider
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
// import HomePage from 'pages/static/HomePage';
import { Helmet } from 'react-helmet';


ReactGA.initialize('G-N9PCEGYX1G');


const useDynamicTitle = () => {
  const ref = useRef(null);
  useEffect(() => {
    const h1Element = ref.current?.querySelector('h1');
    if (h1Element) {
      document.title = h1Element.textContent; // Set document title dynamically
    }
  }, []);

  return ref;
};

const MetaSeo = () => {
  const title = 'Trouvai - Boost your sales with AI Optimized Top-of-Funnel';
  const description = 'Identify & target high-value customer segments to boost conversion rates and sales by focusing your efforts where it matters most.'
  const canonical = 'https://trouvai.com/contact-us';
  const image = 'https://unsplash.com/photos/photography-of-spot-light-turned-on-mln2ExJIkfc';
  const keywords = 'ai sales tool, top-of-funnel generator, customer intelligence'

  return (
    <Helmet>
      {/* <!-- Title --> */}
      <title>{title}</title>

      {/* <!-- Meta Description (important for SEO) --> */}
      <meta name="description" content={description} />

      {/* <!-- Keywords for SEO --> */}
      <meta name="keywords" content={keywords} />

      {/* <!-- Open Graph Meta Tags (for social media sharing) --> */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={canonical} />
      <meta property="og:type" content="website" />

      {/* <!-- Twitter Card Meta Tags --> */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />

      {/* <!-- Canonical Link (for SEO) --> */}
      <link rel="canonical" href={canonical} />
    </Helmet>
  );
};

const App = () => {
  const ref = useDynamicTitle();
  const [loading, setLoading] = useState(true);

  console.log('loaded');

  useEffect(() => {
    console.log('loaded');
    // fetch dashboard menu from API
    dispatch(fetchMenu()).then(() => {
      setLoading(false);
    });
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search
    });
  }, []);

  if (loading) return <Loader />;

  return (
    <ThemeCustomization>
      <MetaSeo />
      <RTLLayout ref={ref}>
        <Locales>
          <ScrollTop>
            <AuthProvider>
              <>
                <Notistack>
                  <Routes />
                  <Snackbar />
                </Notistack>
              </>
            </AuthProvider>
          </ScrollTop>
        </Locales>
      </RTLLayout>
    </ThemeCustomization>
  );
  // return (
  //   <ThemeCustomization>
  //     <RTLLayout>
  //       <Locales>
  //         <ScrollTop>
  //           <AuthProvider>
  //             <>
  //               <Notistack>
  //                 <Routes />
  //                 <Snackbar />
  //               </Notistack>
  //             </>
  //           </AuthProvider>
  //         </ScrollTop>
  //       </Locales>
  //     </RTLLayout>
  //   </ThemeCustomization>
  // );
};

export default App;
