import { get, post, put, destroy } from 'utils/axios';

// Things we can do to users:
// 1. Create, Get, Update, Delete (pk)
// 2. Get list (count, page)?? probably not...unless for users associated with the company...
// 3. Get members(company_pk)
//
/* Basic CRUD:
index  <- GET all items
single <- GET single item by id
create <- POST an item
update <- PUT data to an item
remove <- DELETE an item
Specific:
singleByEmail
removeAll */

export const userEndpoints = {
  getAll: `/users/`,
  getUser: `/users/`
};

export const Users = {
  me: () => get('/users/me/'),
  index: () => get('/users/'),
  single: (id) => get(`/users/${id}/`),
  singleByEmail: (email) => get(`/users/?email=${email}`),
  create: (params) => post('/users/', params),
  invite: (email) => {
    return post(`/users/invite/`, { email: email });
  },
  reinvite: (id) => {
    return post(`/users/${id}/reinvite/`);
  },
  updatePassword: (id, params) => {
    return post(`/users/${id}/update_password/`, params);
  },
  update: (id, params) => put(`/users/${id}/`, params),
  team: () => {
    return get(`/users/team/`);
  },
  remove: (id) => destroy(`/users/${id}/`)
};
