import React from 'react';

// material-ui
import { Stack, Typography } from '@mui/material';

// Redux Store
import { useSelector } from 'store';

import MainCard from 'components/common/MainCard';
import Avatar from 'components/common/@extended/Avatar';

// ==============================|| DRAWER CONTENT - NAVIGATION CARD ||============================== //

const NavheaderCard = () => {
  const { myCompany } = useSelector((state) => state.company);

  return (
    <MainCard
      sx={{
        border: 'None'
      }}
    >
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1} alignItems="left">
        <Avatar alt="Logo" src={myCompany?.logo ?? ''} sx={{ width: 25, height: 25 }} />
        <Typography variant="h5">{myCompany ? myCompany.name : ''}</Typography>
      </Stack>
    </MainCard>
  );
};

export default NavheaderCard;
