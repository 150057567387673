import { lazy } from 'react';
import CommonLayout from 'components/layout/CommonLayout';
import Loadable from 'components/common/Loadable';
import { Navigate } from 'react-router';

import MainLayout from 'components/layout/MainLayout';

// Main Landing Pages
const HomePage = Loadable(lazy(() => import('pages/static/HomePage')));
const SalesSegmentingLandingPage = Loadable(lazy(() => import('pages/landing-pages/SalesSegmentingLandingPage')));

const Landing = Loadable(lazy(() => import('pages/landing')));
const AboutUsPage = Loadable(lazy(() => import('pages/static/core/AboutUsPage')));
const FaqsPage = Loadable(lazy(() => import('pages/static/core/FaqsPage')));
const AppContactUS = Loadable(lazy(() => import('pages/static/core/ContactUsPage')));
const PrivacyPolicy = Loadable(lazy(() => import('pages/static/core/PrivacyPolicyPage')));
const TermsOfService = Loadable(lazy(() => import('pages/static/core/TermsOfServicePage')));

// Business Segment Specific Pages
const FinancialForecastPage = Loadable(lazy(() => import('pages/financial/FinancialForecastPage')));
const BusinessSegments = Loadable(lazy(() => import('pages/discovery/BusinessSegmentsPage')));
const DiscoveryPage = Loadable(lazy(() => import('pages/discovery/DiscoveryPage')));
const StartupPipelinePage = Loadable(lazy(() => import('pages/discovery/StartupPipelinePage')));
const KnownBlogsListPage = Loadable(lazy(() => import('pages/discovery/resources/KnownBlogsListPage')));

const IdeationValidation = Loadable(lazy(() => import('pages/discovery/segments/IdeationValidationPage')));
const BusinessFormationLegal = Loadable(lazy(() => import('pages/discovery/segments/BusinessFormationLegalPage')));
const FinancialSetupPlanning = Loadable(lazy(() => import('pages/discovery/segments/FinancialSetupPlanningPage')));
const ProductDevelopmentOperations = Loadable(lazy(() => import('pages/discovery/segments/ProductDevelopmentOperationsPage')));
const MarketingSales = Loadable(lazy(() => import('pages/discovery/segments/MarketingSalesPage')));
const Marketing = Loadable(lazy(() => import('pages/discovery/marketing/MarketingPage')));
const BrandingOnlinePresence = Loadable(lazy(() => import('pages/discovery/segments/BrandingOnlinePresencePage')));
const OperationsTeamManagement = Loadable(lazy(() => import('pages/discovery/segments/OperationsTeamManagementPage')));
const FinancialHealthGrowth = Loadable(lazy(() => import('pages/discovery/segments/FinancialHealthGrowthPage')));
const AIPoweredInsights = Loadable(lazy(() => import('pages/discovery/segments/AIPoweredInsightsPage')));
const ComplianceRiskManagement = Loadable(lazy(() => import('pages/discovery/segments/ComplianceRiskManagementPage')));

// Topics
const Finance = Loadable(lazy(() => import('pages/discovery/finance/FinancePage')));

// Articles
const FinancialLandingPage = Loadable(lazy(() => import('pages/static/FinancialLandingPage')));
const MarketSizingArticle = Loadable(lazy(() => import('pages/market-analysis/MarketSizingArticlePage')));

// Financial Pages
const FinancialModelerPage = Loadable(lazy(() => import('pages/financial/FinancialModelerPage')));
// const IncomeStatementPage = Loadable(lazy(() => import('@/sections/financial/IncomeStatementPage')));
const GoToMarketDashboard = Loadable(lazy(() => import('pages/go-to-market/GoToMarketDashboard')));
const StrategyDashboard = Loadable(lazy(() => import('pages/go-to-market/StrategyDashboard')));
const CustomerSegmentation = Loadable(lazy(() => import('pages/go-to-market/CustomerSegmentationPage')));
const AiInterfaceGenerator = Loadable(lazy(() => import('pages/ai/AiInterfaceGenerator')));

// Go To Market & Market Segmentations
const MarketSegmentListPage = Loadable(lazy(() => import('pages/go-to-market/MarketSegmentListPage')));
const MarketSegmentAnalysis = Loadable(lazy(() => import('pages/go-to-market/MarketSegmentAnalysis')));
const MarketSegmentDetailPage = Loadable(lazy(() => import('pages/go-to-market/MarketSegmentDetailPage')));
const MarketSearchAndFilterPage = Loadable(lazy(() => import('pages/market-analysis/MarketSearchAndFilterPage')));
const MarketSegmentFitPage = Loadable(lazy(() => import('pages/go-to-market/MarketSegmentFitPage')));

//
const MarketOpportunityAnalysis = Loadable(lazy(() => import('pages/market-analysis/MarketOpportunityAnalysis')));
const ExperimentListPage = Loadable(lazy(() => import('pages/expirements/ExperimentListPage')));

// ==============================|| AUTH ROUTING ||============================== //

const CoreRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: <CommonLayout layout="simple" />,
      children: [
        {
          path: '',
          element: <SalesSegmentingLandingPage />
        },
        {
          path: '/home',
          element: <HomePage />
        },
        {
          path: '/landing',
          element: <Landing />
        },
        {
          path: 'about-us',
          element: <AboutUsPage />
        },
        {
          path: 'faqs',
          element: <FaqsPage />
        },
        {
          path: 'contact-us',
          element: <AppContactUS />
        },
        {
          path: 'privacy',
          element: <PrivacyPolicy />
        },
        {
          path: 'terms-of-service',
          element: <TermsOfService />
        },
        {
          path: 'discover',
          element: <DiscoveryPage />
        },
        {
          path: 'startup-stages',
          element: <StartupPipelinePage />
        },
        {
          path: 'business-segments',
          element: <BusinessSegments />
        },
        {
          path: 'customer-segmentation',
          element: <CustomerSegmentation />
        },
        {
          path: 'vc-blog-list',
          element: <KnownBlogsListPage />
        },
        // Business Segment Specific Routes
        {
          path: 'business-segments/ideation-validation',
          element: <IdeationValidation />
        },
        {
          path: 'business-segments/business-formation-legal',
          element: <BusinessFormationLegal />
        },
        {
          path: 'business-segments/financial-setup-planning',
          element: <FinancialSetupPlanning />
        },
        {
          path: 'business-segments/product-development-operations',
          element: <ProductDevelopmentOperations />
        },
        {
          path: 'business-segments/marketing-sales',
          element: <MarketingSales />
        },
        {
          path: 'business-segments/branding-online-presence',
          element: <BrandingOnlinePresence />
        },
        {
          path: 'business-segments/operations-team-management',
          element: <OperationsTeamManagement />
        },
        {
          path: 'business-segments/financial-health-growth',
          element: <FinancialHealthGrowth />
        },
        {
          path: 'business-segments/ai-powered-insights',
          element: <AIPoweredInsights />
        },
        {
          path: 'business-segments/compliance-risk-management',
          element: <ComplianceRiskManagement />
        },
        {
          path: '*',
          element: <Navigate to={'/'} />
        }
      ]
    },
    {
      path: '/marketing',
      element: <CommonLayout layout="simple" />,
      children: [
        {
          path: '',
          element: <Marketing />
        }
      ]
    },
    {
      path: '/finance',
      element: <CommonLayout layout="simple" />,
      children: [
        {
          path: '',
          element: <Finance />
        }
      ]
    },
    {
      path: '/go-to-market',
      element: <CommonLayout layout="simple" />,
      children: [
        {
          path: '',
          element: <StrategyDashboard />
        },
        {
          path: 'sales',
          element: <GoToMarketDashboard />
        },
        {
          path: 'ai',
          element: <AiInterfaceGenerator />
        },
        {
          path: 'market-segments',
          element: <MarketSegmentAnalysis />
        },
        {
          path: 'search',
          element: <MarketSearchAndFilterPage />
        }
      ]
    },
    {
      path: '/market-sizing',
      element: <CommonLayout layout="simple" />,
      children: [
        {
          path: '',
          element: <MarketSizingArticle />
        }
      ]
    },
    {
      path: '/financial-forecasting',
      element: <CommonLayout layout="simple" />,
      children: [
        {
          path: '',
          element: <FinancialForecastPage />
        },
        {
          path: 'try-free',
          element: <FinancialLandingPage />
        },
        {
          path: 'financial-modeling',
          element: <FinancialModelerPage />
        }
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          path: 'app',
          children: [
            {
              path: '',
              element: <StrategyDashboard />
            },
            {
              path: 'sales',
              element: <GoToMarketDashboard />
            },
            {
              path: 'ai',
              element: <AiInterfaceGenerator />
            },
            {
              path: 'experiments',
              element: <ExperimentListPage />
            },
            {
              path: 'market-segments',
              element: <MarketSegmentListPage />
            },
            {
              path: 'market-segments/analytics',
              element: <MarketSegmentAnalysis />
            },
            {
              path: 'market-segments/search',
              element: <MarketSearchAndFilterPage />
            },
            {
              path: 'market-segments/details',
              element: <MarketSegmentDetailPage />
            },
            {
              path: 'market-segments/fit',
              element: <MarketSegmentFitPage />
            },
            {
              path: 'sizing',
              element: <MarketOpportunityAnalysis />
            }
          ]
        }
      ]
    }
  ]
};

export default CoreRoutes;
