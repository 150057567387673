// import { apiClient, setAuthToken, unsetAuthToken } from "../AxiosConfig";

// Custom API Auth URL
export const backendAuthUrl = process.env.REACT_APP_BACKEND_API_URL + process.env.REACT_APP_BACKEND_AUTH_ENDPOINT;

export const backendAuthEndpoints = {
  obtainTokenPair: `/token/`,
  refreshToken: `/token/refresh/`,
  verifyToken: `/token/verify/`
};
export const accessTokenValidMinutes = 100;

const updateTokenExpirationTime = (tokenValidMinutes) => {
  const now = new Date();
  const expirationTime = new Date(now.getTime() + tokenValidMinutes * 60000);

  localStorage.setItem('tokenExp', expirationTime);
};

export const loginUser = async (loginPayload) => {
  return apiClient.post(backendAuthEndpoints.obtainTokenPair, loginPayload).then((response) => {
    //get token from response
    const access = response.data.access;
    const refresh = response.data.refresh;

    //set JWT token to local & save expiration time
    localStorage.setItem('access', access);
    localStorage.setItem('refresh', refresh);
    updateTokenExpirationTime(accessTokenValidMinutes);

    //set token to axios common header
    setAuthToken();
    return response;
  });
};

export const refreshLoginToken = async (refreshToken) => {
  console.log('refreshing access token...');
  // apiClient{
  //     method: "post",
  //     url: backendAuthEndpoints,
  //     data: bodyFormData,
  //     headers: { "Content-Type": "multipart/form-data" },
  //   }
  return apiClient
    .post(
      backendAuthEndpoints.refreshToken,
      [
        {
          refresh: refreshToken
        }
      ],
      {
        headers: { 'Content-Type': 'application/json', accept: application / json }
      }
    )
    .then((response) => {
      //get token from response
      const access = response.data.access;

      console.log(`New access token ${access}`);
      //set JWT token to local
      localStorage.setItem('access', access);
      updateTokenExpirationTime(accessTokenValidMinutes);

      //set token to axios common header
      setAuthToken();
      return response;
    });
};

export const verifyTokenValidity = async (accessToken) => {
  // requires in the form of {'refresh': <token_value> }
  return apiClient.post(backendAuthEndpoints.verifyToken, accessToken).then((response) => {
    console.log(response);
    const code = response.code;

    if (code === 'token_not_valid') {
      console.log(code);
    }

    return response;
  });
};

export const logoutUser = () => {
  unsetAuthToken();
  localStorage.removeItem('access');
  localStorage.removeItem('refresh');
  localStorage.removeItem('tokenExp');
  console.log('Removed auth token');
  return true;
};

export const userIsAuthenticated = async () => {
  let flag = false;
  const access = localStorage.getItem('access');
  const refresh = localStorage.getItem('refresh');

  const now = new Date().getTime();
  const tokenExp = localStorage.getItem('tokenExp');
  console.log(`Token expiration - ${tokenExp}`);

  if (tokenExp) {
    const expirationTime = new Date(tokenExp).getTime();
    if (now > expirationTime) {
      refreshLoginToken(refresh)
        .then(() => {
          return true;
        })
        .catch((err) => {
          console.log(err);
          // handle error? or pass along?
          return false;
        });
    } else {
      flag = true;
    }
  }

  console.log(access);
  console.log(`User is authenticated - ${flag}`);

  return flag;
};
